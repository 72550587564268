/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable import/extensions */
import React, { useState, useContext, useEffect } from "react";
import {
  Stack,
  StackItem,
  PrimaryButton,
  IChoiceGroupOption,
  ChoiceGroup,
  IChoiceGroup,
  ImageFit,
  Spinner,
  SpinnerSize,
  IIconProps,
  Button,
  Checkbox,
  IDragOptions,
  ContextualMenu,
  PivotItem,
  ActionButton,
  TooltipHost,
  TextField,
  DefaultButton,
  Icon,
  Label,
  Dropdown,
  IDropdown,
  IDropdownOption,
} from "office-ui-fabric-react";
import { ICSSRule } from "@uifabric/merge-styles";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useForm, FormContext, Controller } from "react-hook-form";
import theme from "../../asset/themes/defaultTheme";
import styled from "../GuestDetail/GuestDetail.styled";
import { Text, LanguageContext } from "../../providers/LanguageProvider";
import {
  GuestPortalConstants,
  NameRegex,
  PhoneRegex,
  CompanyRegex,
  IndividualsAvatarNameList,
  LandmarksAvatarNameList,
} from "../../config/constants";
import Config from "../../config/config";
import VehicleDetail from "./VehicleDetail/VehicleDetail";
import * as GuestAction from "../../store/guest/guestAction";
import * as BuildingAction from "../../store/building/buildingAction";
import * as GuestVisitSelector from "../../store/guest/guestSelector";
import { RootState } from "../../store/rootReducer";
import {
  GuestVehicleDetailsType,
  VehicleDetailsType,
} from "../../store/guest/guestType";
import { guestVehicleResource, vehicle } from "../../store/guest/guestResource";
import { countryData } from "../../asset/resources/countryResourceSelector";
import msalAuthenticator from "../../auth/msalAuthenticator";
import * as BuildingSelector from "../../store/building/buildingSelector";
import ErrorBar from "../ErrorBar/ErrorBar";
import config from "../../env/index";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import AvatarPicker from "./AvatarPicker/AvatarPicker";
import AutoCompleteDropdown from "../AutoCompleteDropdown/AutoCompleteDropdown";
import style from "../AutoCompleteDropdown/AutoCompleteDropdown.styled";
import peopleImgPath from "../../asset/images/People.png";
import CountryDropdown from "./CountryDropdown/CountryDropdown";

interface Props {
  setIsModalOpen(boolean);
  isVehicleStateVisible: boolean | undefined;
  isPage: boolean | false;
  vehForm: boolean | false;
  onSave: ((arg0: boolean) => any) | null;
  buildingName?: string | undefined | null;
  visitTiming?: string | undefined | null;
  visitId?: string | undefined | null;
  vehInfo?: VehicleDetailsType | undefined | null;
  numberOfFutureVisits?: number | undefined | null;
  titleId?: string;
}

const GuestDetail: React.FC<Props> = ({
  setIsModalOpen,
  isVehicleStateVisible,
  isPage,
  vehForm,
  onSave,
  buildingName,
  visitTiming,
  visitId,
  vehInfo,
  numberOfFutureVisits,
  titleId,
}) => {
  const methods = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GuestAction.fetchGuestVisitDetails());
    console.log("fetch guest details");
  }, []);

  /** Resetting updateGuestStatus to initial value on component unmount */
  useEffect(
    () => () => {
      dispatch(
        GuestAction.updateGuestVisitDetailsSuccess(
          GuestPortalConstants.DefaultStatus
        )
      );
    },
    []
  );

  const {
    isBuildingParkingAvailable,
    buildingListWithParking,
    isBuildingConfigLoaded,
    visitBuildingCountry,
  } = useSelector(
    (state: RootState) => ({
      isBuildingParkingAvailable:
        BuildingSelector.buildingParkingConfigSelector(state),
      buildingListWithParking:
        BuildingSelector.buildingWithParkingSelector(state),
      isBuildingConfigLoaded: BuildingSelector.isBuildingConfigLoaded(state),
      visitBuildingCountry: BuildingSelector.buildingCountrySelector(state),
    }),
    shallowEqual
  );
  const { guestVisitDetail, guestUpdateStatus, isGuestLoaded } = useSelector(
    (state: RootState) => ({
      guestVisitDetail: GuestVisitSelector.guestSelector(state),
      guestUpdateStatus: GuestVisitSelector.guestUpdateStatusSelector(state),
      isGuestLoaded: GuestVisitSelector.guestDetailLoadedSelector(state),
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(
      BuildingAction.fetchBuildingConfigs(
        guestVisitDetail && guestVisitDetail.visitList
          ? guestVisitDetail.visitList
          : []
      )
    );
  }, [guestVisitDetail]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (isGuestLoaded === true && isBuildingConfigLoaded === true) {
      setShowLoader(false);
    }
  }, [isGuestLoaded, isBuildingConfigLoaded]);

  const [showSubmitLoader, setShowSubmitLoader] = useState(false);
  const dropdownRef = React.createRef<IDropdown>();

  /**
   * Applies placeholder styles on placeholder render
   * @param event
   */
  const onRenderPlaceHolder = (event): JSX.Element => (
    <div style={{ width: "100%" }}>
      {isLangRTL && (
        <Icon
          style={{ float: "left" }}
          iconName="ChevronDown"
          aria-hidden="true"
        />
      )}
      <styled.DropdownPlaceholderStyle isRTL={isLangRTL}>
        <Text tid={event.placeholder} />
      </styled.DropdownPlaceholderStyle>
    </div>
  );

  /**
   * Applies title styles on title render
   * @param event
   */
  const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => (
    <div style={{ width: "100%" }}>
      {isLangRTL && (
        <Icon
          style={{ float: "left" }}
          iconName="ChevronDown"
          aria-hidden="true"
        />
      )}
      {options && options.length ? options[0].text : ""}
    </div>
  );

  /**
   * Applies caretdown styles on caretdown render
   * @param event
   */
  const onRenderCaretDown = (event): JSX.Element => (
    <span>
      {!isLangRTL && <Icon iconName="ChevronDown" aria-hidden="true" />}
    </span>
  );

  /**
   * Called on dismiss of the error message
   */
  function onDismissCallout(): any {
    dispatch(
      GuestAction.updateGuestVisitDetailsSuccess(
        GuestPortalConstants.DefaultStatus
      )
    );
  }
  let timeoutId;
  /**
   * Called on failure message show
   */
  function onShowCallout(): any {
    // Timeout to hide message after the configured seconds
    timeoutId = setTimeout(onDismissCallout, 5000);
  }

  useEffect(() => {
    if (guestUpdateStatus !== GuestPortalConstants.DefaultStatus) {
      setShowSubmitLoader(false);
    }
    if (guestUpdateStatus === GuestPortalConstants.Failure) {
      onShowCallout();
    }
    if (isPage && guestUpdateStatus === GuestPortalConstants.Success) {
      onSave ? onSave(true) : (() => {})();
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [guestUpdateStatus]);

  // TO-DO: Add the avatar images into an external container than having it in assets.

  const [countryOptions, setCountryOptions] = useState([{ key: "", text: "" }]);

  useEffect(() => {
    const options = countryData.map((g) => ({
      key: g.countryShortCode,
      text: g.countryName,
    }));
    setCountryOptions(options);
  }, []);

  const countryNameOptions: string[] = [];
  countryOptions.forEach((item) => {
    countryNameOptions.push(item.text);
  });

  const { guest } = guestVisitDetail;

  const defaultImageName = GuestPortalConstants.DefaultImageName;
  const [displayNameValue, setDisplayNameValue] = useState("");
  const languageContext = useContext(LanguageContext);
  const isNewGuest = !(
    guestVisitDetail.guest &&
    guestVisitDetail.guest.emailId &&
    guestVisitDetail.guest.firstName
  );

  const AvatarPickerResources = {
    individualsTab: languageContext.dictionary.individualsTab,
    landmarksTab: languageContext.dictionary.landmarksTab,
    closeAvatarModalAriaLabel:
      languageContext.dictionary.closeAvatarModalAriaLabel,
  };

  const choiceOptions: IChoiceGroupOption[] = [
    {
      key: GuestPortalConstants.YesKey,
      text: languageContext.dictionary.Yes,
      styles: styled.choiceOptionStyle,
    },
    {
      key: GuestPortalConstants.NoKey,
      text: languageContext.dictionary.No,
      styles: {
        root: {
          fontWeight: `${theme.fontWeightBold}` as unknown as ICSSRule,
          paddingLeft: "10%",
        },
      },
    },
  ];

  const rtlChoiceOptions: IChoiceGroupOption[] = [
    {
      key: GuestPortalConstants.NoKey,
      text: languageContext.dictionary.No,
      styles: {
        root: {
          fontWeight: `${theme.fontWeightBold}` as unknown as ICSSRule,
          paddingRight: "10%",
        },
      },
    },
    {
      key: GuestPortalConstants.YesKey,
      text: languageContext.dictionary.Yes,
      styles: {
        root: {
          fontWeight: `${theme.fontWeightBold}` as unknown as ICSSRule,
        },
      },
    },
  ];

  const choicegroupOptionRef = React.createRef<IChoiceGroup>();

  const [show, setShow] = useState(true);
  /**
   * handles vehicle details show depending on parking option selection
   * @param option
   */
  const handleShow = (option: IChoiceGroupOption): void => {
    setShow(true);
    if (option.key === choiceOptions[1].key) setShow(false);
    guestVisitDetail.isParkingOnCampus = show;
  };

  // Only for the first checkbox, which is controlled
  const [isVehDefault, setIsChecked] = React.useState(true);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onDefalutChange = (e) => {
    setIsChecked(!isVehDefault);
    guestVisitDetail.isVehicleDefault = !isVehDefault;
  };

  const [avatarshow, setAvatarShow] = useState(false);
  const [editDisplayName, setEditDisplayName] = useState(false);
  const [prefixValue, setPrefixValue] = useState(guest ? guest.prefix : "");
  const [firstNameValue, setFirstNameValue] = useState(
    guest ? guest.firstName : ""
  );
  const [middleNameValue, setMiddleNameValue] = useState(
    guest ? guest.middleName : ""
  );
  const [lastNameValue, setLastNameValue] = useState(
    guest ? guest.lastName : ""
  );
  const [selectedCountry, setSelectedCountry] = useState(
    guest ? guest.country : ""
  );

  /**
   * handles close button cabability on avatar modal
   */
  const handleAvatarDialogueClose = (): void => setAvatarShow(false);

  /**
   * changes profile image as selected avatar and closes avatar modal
   * @param event
   */
  const selectAvatar = (event): void => {
    guestVisitDetail.guest.avatarName = event.target.alt;
    handleAvatarDialogueClose();
  };

  const isLangRTL =
    languageContext.language.languageType ===
    GuestPortalConstants.RtlLanguageType;

  const [hideDialog, setHideDialog] = useState(true);

  const onSubmit = (data): void => {
    setShowSubmitLoader(true);
    const guestVehicleDetail: GuestVehicleDetailsType = guestVehicleResource;

    const updatedVehDetails = vehicle;

    if (isBuildingParkingAvailable && vehForm) {
      guestVehicleDetail.guest = guestVisitDetail.guest;
      guestVehicleDetail.isGuestVehDefault = isVehDefault;
      guestVehicleDetail.visitId = visitId;
      guestVehicleDetail.guest.emailId = msalAuthenticator.getUserEmail();
      if (!show) {
        guestVehicleDetail.vehicleInfo = null;
      } else {
        guestVehicleDetail.vehicleInfo = updatedVehDetails;
        guestVehicleDetail.vehicleInfo.make = data.vehicleInfo?.make;
        guestVehicleDetail.vehicleInfo.model = data.vehicleInfo?.model;
        guestVehicleDetail.vehicleInfo.registrationId =
          data.vehicleInfo?.licensePlate;
        guestVehicleDetail.vehicleInfo.type = data.vehicleInfo?.type;
        if (isVehicleStateVisible) {
          guestVehicleDetail.vehicleInfo.registrationState =
            data.vehicleInfo?.registrationState;
        }
      }
    } else {
      guestVehicleDetail.vehicleInfo = updatedVehDetails;
      guestVehicleDetail.guest.firstName = isLangRTL
        ? data.firstNameRTL
        : data.firstName;
      guestVehicleDetail.guest.lastName = isLangRTL
        ? data.lastNameRTL
        : data.lastName;
      guestVehicleDetail.guest.phone = data.phone;
      guestVehicleDetail.guest.companyName = data.company;
      guestVehicleDetail.guest.emailId = msalAuthenticator.getUserEmail();
      guestVehicleDetail.guest.prefix = data.prefix;
      guestVehicleDetail.guest.middleName = data.middleName;
      guestVehicleDetail.guest.country = data.country;
      guestVehicleDetail.guest.displayName = guest ? guest.displayName : "";
      guestVehicleDetail.guest.contactId = guest ? guest.contactId : "";
      guestVehicleDetail.vehicleInfo.make =
        guestVisitDetail && guestVisitDetail.vehicleInfo
          ? guestVisitDetail.vehicleInfo.make
          : "";
      guestVehicleDetail.vehicleInfo.model =
        guestVisitDetail && guestVisitDetail.vehicleInfo
          ? guestVisitDetail.vehicleInfo.model
          : "";
      guestVehicleDetail.vehicleInfo.registrationId =
        guestVisitDetail && guestVisitDetail.vehicleInfo
          ? guestVisitDetail.vehicleInfo.registrationId
          : "";
      guestVehicleDetail.vehicleInfo.type =
        guestVisitDetail && guestVisitDetail.vehicleInfo
          ? guestVisitDetail.vehicleInfo.type
          : "" ?? "";
      guestVehicleDetail.vehicleInfo.registrationState =
        guestVisitDetail && guestVisitDetail.vehicleInfo
          ? guestVisitDetail.vehicleInfo.registrationState
          : "";
    }
    guestVehicleDetail.guest.avatarName =
      guestVisitDetail && guestVisitDetail.guest
        ? guestVisitDetail.guest.avatarName
        : "";

    dispatch(GuestAction.updateGuestDetails(guestVehicleDetail));
    setHideDialog(true);
  };
  const onCloseDialog = (): any => {
    setHideDialog(true);
  };

  const vehFormDialogProps = {
    isRTL: { isLangRTL },
    hideDialog,
    onCloseDialog,
    PageTitle: Text({ tid: "ConfirmVehUpdate" }),
    // eslint-disable-next-line max-len
    PageSubText: Text({ tid: "VehUpdateDescription" }).replace(
      "0",
      numberOfFutureVisits
    ),
    isBlocking: true,
    onLeavePage: methods.handleSubmit(onSubmit),
    buttonCancel: Text({ tid: "Cancel" }),
    buttonLeavePage: Text({ tid: "Continue" }),
  };

  /**
   * Handler for Save Button
   */
  const onSaveButtonClick = (): void => {
    setHideDialog(false);
  };

  const cancelIcon: IIconProps = { iconName: "Cancel" };
  // const searchInputProp: ShorthandValue<DropdownSearchInputProps> = {
  //   styles: style.searchInputComponentStyle,
  // };

  return (
    <FormContext {...methods}>
      <form>
        {showLoader && (
          <styled.Loader>
            <Spinner styles={styled.LoaderStyle} size={SpinnerSize.large} />
          </styled.Loader>
        )}
        <styled.CloseButtonContainer>
          <styled.CloseButton
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => setIsModalOpen(false)}
          />
        </styled.CloseButtonContainer>

        <Stack styles={styled.Outermost}>
          {!showLoader &&
            guestUpdateStatus === GuestPortalConstants.Failure && (
              <ErrorBar
                onDismiss={onDismissCallout}
                errorText="UpdateFailureMessage"
              />
            )}
          <styled.FormContainer
            parkingSpace={isBuildingParkingAvailable}
            isPage={isPage}
          >
            <Stack horizontal>
              <StackItem>
                <styled.FormTitle
                  isRTL={isLangRTL}
                  id={titleId}
                  role="heading"
                  aria-level={1}
                >
                  <Text tid="GuestFormHeader" />
                </styled.FormTitle>
              </StackItem>
            </Stack>
            <styled.FormSubTitle isRTL={isLangRTL}>
              <Text tid="GuestFormSubHeader" />
            </styled.FormSubTitle>
            {!showLoader &&
              guestUpdateStatus !== GuestPortalConstants.Success &&
              !vehForm && (
                <Stack.Item styles={styled.LeftStackItemStyles}>
                  <Stack
                    tabIndex={0}
                    styles={{ root: { textOverflow: "ellipsis" } }}
                  >
                    <Stack.Item>
                      <Controller
                        name="avatarName"
                        ref={methods.register}
                        as={
                          <Stack horizontal>
                            <styled.GuestImage
                              isPage={isPage}
                              src={
                                guestVisitDetail.guest &&
                                guestVisitDetail.guest.avatarName &&
                                guestVisitDetail.guest.avatarName !== ""
                                  ? `${config.CDN_AVATAR_URL}${guestVisitDetail.guest.avatarName}`
                                  : peopleImgPath
                              }
                              imageFit={ImageFit.contain}
                              alt={languageContext.dictionary.ChooseAvatar}
                            />
                            <styled.stackItemStyles align="end">
                              <TooltipHost content="Edit guest avatar">
                                <ActionButton
                                  alt="Edit guest avatar"
                                  aria-label="Edit guest avatar"
                                  // disabled={guestDetailsProps.isVisitNotEditable}
                                  iconProps={{
                                    iconName: "Edit",
                                    style: {
                                      color: "black",
                                      fontSize: theme.fontSizeMedium,
                                    },
                                  }}
                                  onClick={() => setAvatarShow(true)}
                                />
                              </TooltipHost>
                            </styled.stackItemStyles>
                          </Stack>
                        }
                        control={methods.control}
                      />
                    </Stack.Item>
                    {!editDisplayName ? (
                      <>
                        <Stack.Item>
                          {guest && guest.displayName ? (
                            <Label styles={styled.displayNameStyles}>
                              {`${prefixValue ? prefixValue : ""} ${
                                guest.displayName
                              }`}
                            </Label>
                          ) : (
                            <Label styles={styled.displayNameStyles}>
                              {`${
                                prefixValue ? prefixValue : ""
                              } ${firstNameValue}  ${
                                middleNameValue ? middleNameValue : ""
                              }  ${lastNameValue}`}
                            </Label>
                          )}
                        </Stack.Item>
                        <Stack.Item>
                          <DefaultButton
                            styles={styled.UpdateDisplayNameButtonStyles}
                            onClick={() => {
                              const displayName =
                                methods.getValues()["displayName"] !== ""
                                  ? methods.getValues()["displayName"]
                                  : `${firstNameValue} ${
                                      middleNameValue ? middleNameValue : ""
                                    } 
                                  ${lastNameValue}`.replace(/\s\s+/g, " ");

                              methods.setValue("displayName", displayName);
                              setDisplayNameValue(displayName);
                              setEditDisplayName(true);
                            }}
                          >
                            {languageContext.dictionary.UpdateDisplayNameText}
                          </DefaultButton>
                        </Stack.Item>
                      </>
                    ) : (
                      <>
                        <Stack.Item>
                          <Stack horizontal tokens={{ childrenGap: 10 }}>
                            {prefixValue && prefixValue !== "" && (
                              <styled.displayNameLabelStyles>
                                {prefixValue}
                              </styled.displayNameLabelStyles>
                            )}
                            <Controller
                              name="displayName"
                              ref={methods.register}
                              shouldUnregister="False"
                              as={({ field }) => (
                                <TextField
                                  {...field}
                                  placeholder={
                                    languageContext.dictionary.DisplayNameText
                                  }
                                  styles={styled.TextFieldStyles}
                                  onFocus={(e) => {
                                    e.target.select();
                                  }}
                                  autoFocus
                                  onBlur={(e) => {
                                    if (
                                      e.target.value &&
                                      editDisplayName &&
                                      guest
                                    ) {
                                      methods.setValue(
                                        "displayName",
                                        e.target.value
                                      );
                                      guest.displayName = e.target.value;
                                    }
                                    setEditDisplayName(false);
                                  }}
                                  defaultValue={guest ? guest.displayName : ""}
                                />
                              )}
                              control={methods.control}
                            />
                          </Stack>
                        </Stack.Item>
                        <Stack.Item>
                          <DefaultButton
                            styles={styled.CancelDisplayNameButtonStyles}
                            onMouseDown={(e) => {
                              e.preventDefault();
                            }}
                            onClick={() => {
                              methods.setValue("displayName", displayNameValue);
                              setEditDisplayName(false);
                            }}
                          >
                            {languageContext.dictionary.Cancel}
                          </DefaultButton>
                        </Stack.Item>
                      </>
                    )}

                    <AvatarPicker
                      avatarshow={avatarshow}
                      handleAvatarDialogueClose={handleAvatarDialogueClose}
                      selectAvatar={selectAvatar}
                      config={config}
                      LandmarksAvatarNameList={LandmarksAvatarNameList}
                      IndividualsAvatarNameList={IndividualsAvatarNameList}
                      AvatarPickerResources={AvatarPickerResources}
                    />
                  </Stack>
                </Stack.Item>
              )}
            {!showLoader &&
              guestUpdateStatus !== GuestPortalConstants.Success &&
              !vehForm && (
                <StackItem>
                  <Stack horizontal>
                    <StackItem styles={styled.PrefixItemWidth}>
                      <styled.FieldLabel isRTL={isLangRTL}>
                        <Text tid="Prefix" />
                      </styled.FieldLabel>
                      <styled.LongTextField
                        isRTL={isLangRTL}
                        name="prefix"
                        ref={methods.register({
                          required: false,
                          maxLength: 4,
                          pattern: NameRegex,
                        })}
                        defaultValue={guest && guest.prefix ? guest.prefix : ""}
                        placeholder={languageContext.dictionary.Prefix}
                        aria-label={languageContext.dictionary.Prefix}
                        onChange={(e) => {
                          methods.setValue("prefix", e.target.value);
                          setPrefixValue(e.target.value);
                        }}
                      />
                      {methods.errors.prefix && (
                        <styled.errorFragment isRTL={isLangRTL}>
                          <Text tid="FieldInvalid" />
                        </styled.errorFragment>
                      )}
                    </StackItem>
                  </Stack>
                  <styled.NameContainer>
                    <styled.NameStackStyle isPage={isPage}>
                      {isLangRTL && (
                        <StackItem
                          styles={styled.NameStackItemStyle(true, false)}
                        >
                          <styled.FieldLabel isRTL>
                            <Text tid="LastName" />
                          </styled.FieldLabel>
                          <styled.TextField
                            isRTL
                            name="lastNameRTL"
                            ref={methods.register({
                              required: true,
                              pattern: NameRegex,
                            })}
                            defaultValue={
                              guest && guest.lastName ? guest.lastName : ""
                            }
                            placeholder={
                              languageContext.dictionary.LastNamePlaceholder
                            }
                            aria-label={languageContext.dictionary.LastName}
                            onChange={(e) => {
                              methods.setValue("lastName", e.target.value);
                              setLastNameValue(e.target.value);
                            }}
                          />
                          {(methods.errors.lastNameRTL as any)?.type ===
                            "required" && (
                            <styled.errorFragment isRTL={isLangRTL}>
                              <Text tid="FieldRequired" />
                            </styled.errorFragment>
                          )}
                          {(methods.errors.lastNameRTL as any)?.type ===
                            "pattern" && (
                            <styled.errorFragment isRTL={isLangRTL}>
                              <Text tid="FieldInvalid" />
                            </styled.errorFragment>
                          )}
                        </StackItem>
                      )}

                      {!isLangRTL && (
                        <StackItem
                          styles={styled.NameStackItemStyle(true, false)}
                        >
                          <styled.FieldLabel isRTL={false}>
                            <Text tid="FirstName" />
                          </styled.FieldLabel>
                          <styled.TextField
                            isRTL={false}
                            name="firstName"
                            ref={methods.register({
                              required: true,
                              pattern: NameRegex,
                            })}
                            defaultValue={
                              guest && guest.firstName ? guest.firstName : ""
                            }
                            placeholder={
                              languageContext.dictionary.FirstNamePlaceholder
                            }
                            aria-label={languageContext.dictionary.FirstName}
                            onChange={(e) => {
                              methods.setValue("firstName", e.target.value);
                              setFirstNameValue(e.target.value);
                            }}
                          />
                          {(methods.errors.firstName as any)?.type ===
                            "required" && (
                            <styled.errorFragment isRTL={isLangRTL}>
                              <Text tid="FieldRequired" />
                            </styled.errorFragment>
                          )}
                          {(methods.errors.firstName as any)?.type ===
                            "pattern" && (
                            <styled.errorFragment isRTL={isLangRTL}>
                              <Text tid="FieldInvalid" />
                            </styled.errorFragment>
                          )}
                        </StackItem>
                      )}
                    </styled.NameStackStyle>
                    <styled.NameStackStyle isPage={isPage}>
                      {
                        <StackItem
                          styles={styled.NameStackItemStyle(true, true)}
                        >
                          <styled.FieldLabel isRTL={isLangRTL}>
                            <Text tid="MiddleName" />
                          </styled.FieldLabel>
                          <styled.TextField
                            isRTL={isLangRTL}
                            name="middleName"
                            ref={methods.register({
                              required: false,
                              pattern: NameRegex,
                            })}
                            defaultValue={
                              guest && guest.middleName ? guest.middleName : ""
                            }
                            placeholder={
                              languageContext.dictionary.MiddleNamePlaceholder
                            }
                            aria-label={languageContext.dictionary.MiddleName}
                            onChange={(e) => {
                              methods.setValue("middleName", e.target.value);
                              setMiddleNameValue(e.target.value);
                            }}
                          />
                          {methods.errors.middleName && (
                            <styled.errorFragment isRTL={isLangRTL}>
                              <Text tid="FieldInvalid" />
                            </styled.errorFragment>
                          )}
                        </StackItem>
                      }
                    </styled.NameStackStyle>
                    <styled.NameStackStyle isPage={isPage}>
                      {isLangRTL && (
                        <StackItem
                          styles={styled.NameStackItemStyle(false, true)}
                        >
                          <styled.FieldLabel isRTL>
                            <Text tid="FirstName" />
                          </styled.FieldLabel>
                          <styled.TextField
                            isRTL
                            name="firstNameRTL"
                            ref={methods.register({
                              required: true,
                              pattern: NameRegex,
                            })}
                            defaultValue={
                              guest && guest.firstName ? guest.firstName : ""
                            }
                            placeholder={
                              languageContext.dictionary.FirstNamePlaceholder
                            }
                            aria-label={languageContext.dictionary.FirstName}
                            onChange={(e) => {
                              methods.setValue("firstName", e.target.value);
                              setFirstNameValue(e.target.value);
                            }}
                          />
                          {(methods.errors.firstNameRTL as any)?.type ===
                            "required" && (
                            <styled.errorFragment isRTL={isLangRTL}>
                              <Text tid="FieldRequired" />
                            </styled.errorFragment>
                          )}
                          {(methods.errors.firstNameRTL as any)?.type ===
                            "pattern" && (
                            <styled.errorFragment isRTL={isLangRTL}>
                              <Text tid="FieldInvalid" />
                            </styled.errorFragment>
                          )}
                        </StackItem>
                      )}

                      {!isLangRTL && (
                        <StackItem
                          styles={styled.NameStackItemStyle(false, true)}
                        >
                          <styled.FieldLabel isRTL={false}>
                            <Text tid="LastName" />
                          </styled.FieldLabel>
                          <styled.TextField
                            isRTL={false}
                            name="lastName"
                            ref={methods.register({
                              required: true,
                              pattern: NameRegex,
                            })}
                            defaultValue={
                              guest && guest.lastName ? guest.lastName : ""
                            }
                            placeholder={
                              languageContext.dictionary.LastNamePlaceholder
                            }
                            aria-label={languageContext.dictionary.LastName}
                            onChange={(e) => {
                              methods.setValue("lastName", e.target.value);
                              setLastNameValue(e.target.value);
                            }}
                          />
                          {(methods.errors.lastName as any)?.type ===
                            "required" && (
                            <styled.errorFragment isRTL={isLangRTL}>
                              <Text tid="FieldRequired" />
                            </styled.errorFragment>
                          )}
                          {(methods.errors.lastName as any)?.type ===
                            "pattern" && (
                            <styled.errorFragment isRTL={isLangRTL}>
                              <Text tid="FieldInvalid" />
                            </styled.errorFragment>
                          )}
                        </StackItem>
                      )}
                    </styled.NameStackStyle>
                  </styled.NameContainer>

                  <styled.NameContainer>
                    <styled.NameStackStyle isPage={isPage}>
                      <StackItem
                        styles={styled.NameStackItemStyle(true, false)}
                      >
                        <styled.FieldLabel isRTL={isLangRTL}>
                          <Text tid="Phone" />
                        </styled.FieldLabel>
                        <styled.LongTextField
                          isRTL={isLangRTL}
                          name="phone"
                          ref={methods.register({
                            required: false,
                            maxLength: 20,
                            pattern: PhoneRegex,
                          })}
                          defaultValue={guest && guest.phone ? guest.phone : ""}
                          placeholder={
                            languageContext.dictionary.PhonePlaceholder
                          }
                          aria-label={
                            languageContext.dictionary.PhonePlaceholder
                          }
                        />
                        {methods.errors.phone && (
                          <styled.errorFragment isRTL={isLangRTL}>
                            <Text tid="FieldInvalid" />
                          </styled.errorFragment>
                        )}
                      </StackItem>
                    </styled.NameStackStyle>

                    <styled.NameStackStyle isPage={isPage}>
                      <StackItem styles={styled.NameStackItemStyle(true, true)}>
                        <styled.FieldLabel isRTL={isLangRTL}>
                          <Text tid="Company" />
                        </styled.FieldLabel>
                        <styled.LongTextField
                          isRTL={isLangRTL}
                          name="company"
                          ref={methods.register({
                            required: false,
                            maxLength: 50,
                            pattern: CompanyRegex,
                          })}
                          defaultValue={
                            guest && guest.companyName ? guest.companyName : ""
                          }
                          placeholder={
                            languageContext.dictionary.CompanyPlaceholder
                          }
                          aria-label={languageContext.dictionary.Company}
                        />
                        {methods.errors.company && (
                          <styled.errorFragment isRTL={isLangRTL}>
                            <Text tid="FieldInvalid" />
                          </styled.errorFragment>
                        )}
                      </StackItem>
                    </styled.NameStackStyle>

                    <styled.NameStackStyle isPage={isPage}>
                      <StackItem
                        styles={styled.NameStackItemStyle(false, true)}
                      >
                        <styled.FieldLabel isRTL={isLangRTL} disabled={false}>
                          <Text tid="Country" />
                        </styled.FieldLabel>
                      </StackItem>
                      <StackItem
                        styles={styled.NameStackItemStyle(false, true)}
                      >
                        <Controller
                          name="country"
                          ref={methods.register}
                          rules={{
                            required: false,
                          }}
                          as={
                            <CountryDropdown
                              disabled={false}
                              componentRef={dropdownRef}
                              styles={styled.CountryDropdownStyles(isLangRTL)}
                              placeholder="Select country"
                              selectedKey={selectedCountry}
                              aria-label={languageContext.dictionary.Country}
                            />
                          }
                          control={methods.control}
                          onChange={([event, value]) => {
                            setSelectedCountry(value.key);
                            methods.setValue("country", value.key);
                            if (guest) guest.country = value.key;
                            return value.text;
                          }}
                        />
                      </StackItem>
                    </styled.NameStackStyle>
                  </styled.NameContainer>
                </StackItem>
              )}
          </styled.FormContainer>
          {!showLoader &&
            guestUpdateStatus !== GuestPortalConstants.Success &&
            vehForm && (
              // Vehicle Details Container
              <styled.VehFormContainer
                parkingSpace={isBuildingParkingAvailable}
                isPage={isPage}
              >
                <styled.FormTitle
                  isRTL={isLangRTL}
                  role="heading"
                  aria-level={2}
                >
                  <Text tid="VehFormHeader" />
                </styled.FormTitle>
                <styled.FormSubTitle isRTL={isLangRTL}>
                  <Text tid="VehFormSubHeader" />
                </styled.FormSubTitle>
                <styled.MeetingDetailsTitle isRTL={isLangRTL}>
                  <Text tid="MettingDetails" />
                </styled.MeetingDetailsTitle>
                <StackItem>
                  <styled.MeetingDetailsContainer horizontal isRTL={isLangRTL}>
                    <styled.VehicleMeetingDetailStackStyle isPage={isPage}>
                      <Stack>
                        <styled.FieldLabelName isRTL={isLangRTL}>
                          {isLangRTL
                            ? `${guest.lastName} ${guest.firstName}`
                            : `${guest.firstName} ${guest.lastName}`}
                        </styled.FieldLabelName>
                        <styled.FieldLabelDateLocation isRTL={isLangRTL}>
                          {buildingName}
                        </styled.FieldLabelDateLocation>
                        <styled.FieldLabelDateLocation isRTL={isLangRTL}>
                          {visitTiming}
                        </styled.FieldLabelDateLocation>
                      </Stack>
                    </styled.VehicleMeetingDetailStackStyle>
                    <Stack>
                      <styled.GuestImage
                        isPage={isPage}
                        src={
                          guestVisitDetail.guest &&
                          guestVisitDetail.guest.avatarName
                            ? `${Config.CDN_AVATAR_URL}${guestVisitDetail.guest.avatarName}`
                            : require(`../../asset/images/${defaultImageName}`)
                        }
                        imageFit={ImageFit.contain}
                        alt={languageContext.dictionary.ChooseAvatar}
                      />
                    </Stack>
                  </styled.MeetingDetailsContainer>
                  {isBuildingParkingAvailable && (
                    <Stack horizontal styles={styled.ChoiceGoupStackStyle}>
                      <StackItem>
                        <styled.ParkingLabel isRTL={isLangRTL}>
                          <Text tid="CampusParkingQuestion" />
                        </styled.ParkingLabel>
                      </StackItem>
                      <StackItem>
                        <ChoiceGroup
                          componentRef={choicegroupOptionRef}
                          defaultSelectedKey={choiceOptions[0].key}
                          options={
                            !isLangRTL ? choiceOptions : rtlChoiceOptions
                          }
                          styles={styled.ChoiceGroupStyles(isLangRTL)}
                          onChange={(event, option) =>
                            handleShow(option || choiceOptions[0])
                          }
                        />
                      </StackItem>
                    </Stack>
                  )}
                  {isBuildingParkingAvailable && (
                    <Stack horizontal styles={styled.ChoiceGoupStackStyle}>
                      <VehicleDetail
                        // guestVisitDetailProp={guestVisitDetail}
                        vehicleInfo={vehInfo}
                        isVehicleShow={show}
                        isVehicleStateVisible={isVehicleStateVisible}
                      />
                    </Stack>
                  )}
                </StackItem>
              </styled.VehFormContainer>
            )}
          {!showLoader && vehForm && (
            <styled.CheckBoxStackItem isRTL={isLangRTL}>
              <Checkbox
                label={Text({ tid: "ApplyDefaultVehToFutureVisits" })}
                checked={isVehDefault}
                onChange={onDefalutChange}
              />
            </styled.CheckBoxStackItem>
          )}
        </Stack>
        {isGuestLoaded &&
          !showLoader &&
          guestUpdateStatus !== GuestPortalConstants.Success && (
            <styled.ButtonStackStyle horizontal isPage={isPage}>
              {!isLangRTL && (
                <styled.PrimaryButtonStackItem isRTL={isLangRTL}>
                  {/* confiramtion pupup experience if it is a vehicle form and we are setting default veh for the all upcoming visit */}
                  {vehForm && isVehDefault && (
                    <PrimaryButton
                      styles={styled.ButtonStyle(isLangRTL)}
                      onClick={methods.handleSubmit(onSaveButtonClick)}
                    >
                      <styled.SubmitButtonText>
                        {isPage && isNewGuest && <Text tid="Next" />}
                        {!isPage && <Text tid="Submit" />}
                        {isPage && !isNewGuest && <Text tid="Save" />}
                      </styled.SubmitButtonText>
                      {showSubmitLoader && (
                        <Spinner
                          styles={styled.SubmitLoaderStyle}
                          size={SpinnerSize.small}
                        />
                      )}
                    </PrimaryButton>
                  )}
                  {/* Else */}
                  {((vehForm && !isVehDefault) || !vehForm) && (
                    <PrimaryButton
                      styles={styled.ButtonStyle(isLangRTL)}
                      onClick={methods.handleSubmit(onSubmit)}
                    >
                      <styled.SubmitButtonText>
                        {isPage && isNewGuest && <Text tid="Next" />}
                        {!isPage && <Text tid="Submit" />}
                        {isPage && !isNewGuest && <Text tid="Save" />}
                      </styled.SubmitButtonText>
                      {showSubmitLoader && (
                        <Spinner
                          styles={styled.SubmitLoaderStyle}
                          size={SpinnerSize.small}
                        />
                      )}
                    </PrimaryButton>
                  )}
                </styled.PrimaryButtonStackItem>
              )}
              <styled.CancelButtonStackItem isRTL={isLangRTL}>
                <styled.CancelButton
                  isRTL={isLangRTL}
                  onClick={() => setIsModalOpen(false)}
                  aria-labelledby={languageContext.dictionary.Cancel}
                  aria-describedby="cancel-description"
                >
                  <span id="cancel-description" hidden>
                    {GuestPortalConstants.CancelButtonAriaDescription}
                  </span>
                  <styled.SubmitButtonText>
                    <Text tid="Cancel" />
                  </styled.SubmitButtonText>
                </styled.CancelButton>
              </styled.CancelButtonStackItem>
              {isLangRTL && (
                <styled.PrimaryButtonStackItem isRTL={isLangRTL}>
                  {/* confiramtion pupup experience if it is a vehicle form and we are setting default veh for the all upcoming visit */}
                  {vehForm && isVehDefault && (
                    <PrimaryButton
                      styles={styled.ButtonStyle(isLangRTL)}
                      onClick={() => onSaveButtonClick()}
                    >
                      <styled.SubmitButtonText>
                        {isPage && isNewGuest && <Text tid="Next" />}
                        {!isPage && <Text tid="Submit" />}
                        {isPage && !isNewGuest && <Text tid="Save" />}
                      </styled.SubmitButtonText>
                      {showSubmitLoader && (
                        <Spinner
                          styles={styled.SubmitLoaderStyle}
                          size={SpinnerSize.small}
                        />
                      )}
                    </PrimaryButton>
                  )}
                  {/* Else */}
                  {((vehForm && !isVehDefault) || !vehForm) && (
                    <PrimaryButton
                      styles={styled.ButtonStyle(isLangRTL)}
                      onClick={methods.handleSubmit(onSubmit)}
                    >
                      <styled.SubmitButtonText>
                        {isPage && isNewGuest && <Text tid="Next" />}
                        {!isPage && <Text tid="Submit" />}
                        {isPage && !isNewGuest && <Text tid="Save" />}
                      </styled.SubmitButtonText>
                      {showSubmitLoader && (
                        <Spinner
                          styles={styled.SubmitLoaderStyle}
                          size={SpinnerSize.small}
                        />
                      )}
                    </PrimaryButton>
                  )}
                </styled.PrimaryButtonStackItem>
              )}
            </styled.ButtonStackStyle>
          )}
        <ConfirmationDialog dialogProps={vehFormDialogProps} />

        {!isPage &&
          guestUpdateStatus === GuestPortalConstants.Success &&
          setIsModalOpen(false)}
      </form>
    </FormContext>
  );
};

export default GuestDetail;
