/* eslint-disable complexity */
/* eslint-disable import/extensions */
import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Spinner, SpinnerSize, Stack, StackItem
} from 'office-ui-fabric-react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment-timezone';
import { getRoute } from '../../../routes/routerConfig';
import Config from '../../../config/config';
import styled from './ValidateInfo.styled';
import { Text, LanguageContext } from '../../../providers/LanguageProvider';
import * as GuestAction from '../../../store/guest/guestAction';
import * as GuestVisitSelector from '../../../store/guest/guestSelector';
import * as BuildingAction from '../../../store/building/buildingAction';
import * as BuildingSelector from '../../../store/building/buildingSelector';
import { RootState } from '../../../store/rootReducer';
import msalAuthenticator from '../../../auth/msalAuthenticator';
import { GuestPortalConstants } from '../../../config/constants';
import ValidateCardSection from '../../../components/ValidateCardSection/ValidateCardSection';
import ErrorBar from '../../../components/ErrorBar/ErrorBar';
import MicrosoftLogo from '../../../asset/images/Microsoft-Logo2.png';
import EditPen from '../../../asset/images/editpen.png';
import { checkinDetailsType } from '../../../store/guest/guestType';
import useDateTimeParser from '../../../helpers/hooks/useDateTimeParser';
import peopleImgPath from '../../../asset/images/People.png';

interface Props {
  visitId: string;
  switchView: Function;
  qrCode: string;
}

const ValidateInfo: React.FC<Props> = ({ switchView, visitId, qrCode }) => {
  const dispatch = useDispatch();
  const languageContext = useContext(LanguageContext);
  const guestEmailId = msalAuthenticator.getUserEmail();
  const history = useHistory();
  const isEmailNull = !((guestEmailId && guestEmailId !== ''));
  const defaultImage = GuestPortalConstants.DefaultImageName;

  const {
    guestVisitDetail,
    buildingList,
    checkinVisits,
    checkinStatus
  } = useSelector((state: RootState) => ({
    guestVisitDetail: GuestVisitSelector.guestSelector(state),
    buildingList: BuildingSelector.buildingsSelector(state),
    checkinVisits: GuestVisitSelector.CheckinVisitsSelector(state),
    checkinStatus: GuestVisitSelector.checkinStatusSelector(state)
  }), shallowEqual);

  const {
    isBuildingConfigLoaded
  } = useSelector((state: RootState) => ({
    isBuildingConfigLoaded: BuildingSelector.isBuildingConfigLoaded(state),
  }), shallowEqual);

  useEffect(() => {
    dispatch(BuildingAction.fetchBuildingConfigs(guestVisitDetail
      && guestVisitDetail.visitList ? guestVisitDetail.visitList : []));
  }, [guestVisitDetail]);

  const { guest } = guestVisitDetail;
  const vehicle = guestVisitDetail.vehicleInfo;
  const activeVisit = checkinVisits.filter((visit) => visit.visitId === visitId)[0];
  const visitBuilding = buildingList.find((b) => b.buildingId === activeVisit.buildingId);
  const timeZone = visitBuilding ? visitBuilding.localization.gmtZoneShort
    : GuestPortalConstants.baseTimeZone;
  const hasParking = visitBuilding?.gmsConfigs?.parkingAvailable;
  const additionalVisits = checkinVisits.length - 1;

  useEffect(() => {
    const activeVisit = checkinVisits.filter((visit) => visit.visitId === visitId)[0];
    const visitBuilding = buildingList.find((b) => b.buildingId === activeVisit.buildingId);
    dispatch(BuildingAction.fetchVisitBuilding(visitBuilding));
  }, [checkinVisits, buildingList, visitId]);

  const [showLoader, setShowLoader] = useState(true);
  const [showErrorBar, setShowErrorBar] = useState(false);

  useEffect(() => {
    if (isBuildingConfigLoaded === true) {
      setShowLoader(false);
    }
  }, [isBuildingConfigLoaded]);

  useEffect(() => {
    if (checkinStatus.success === GuestPortalConstants.Success) {
      switchView(GuestPortalConstants.CompleteCheckinView);
    } else if (checkinStatus.success === GuestPortalConstants.Failure) {
      setShowErrorBar(true);
    }
  }, [checkinStatus]);

  var firstLineText: string;
  var lastLineText: string;

  if(guest.displayName == '' || guest.displayName == undefined)
    guest.displayName = guest.firstName + ' ' + (guest.middleName? guest.middleName : '') + ' ' + guest.lastName;
  const nameLength = guest.displayName.split(' ').length;
  if(nameLength > 1){
    firstLineText = guest.prefix? guest.prefix : '';     
    for(var i=0; i<nameLength-1; i++){
        firstLineText = firstLineText + ' ' + guest.displayName.split(' ')[i];
    }
    lastLineText = guest.displayName.split(' ')[nameLength - 1];
  } else {
    firstLineText = (guest.prefix? guest.prefix : '') + ' ' + guest.displayName;
    lastLineText = '';
  }


  const hasActiveVisits = !!(checkinVisits && checkinVisits.length);

  const isLangRTL = (languageContext.language.languageType
    === GuestPortalConstants.RtlLanguageType);

  const meetingDetails = {
    showAvatar: true,
    avatarName: '',
    details: [activeVisit ? activeVisit.hostName : '',
    visitBuilding ? visitBuilding.buildingName : '',
    useDateTimeParser(activeVisit && activeVisit.scheduledStartTime
      ? activeVisit.scheduledStartTime : '', activeVisit && activeVisit.scheduledEndTime
      ? activeVisit.scheduledEndTime : '',
      visitBuilding?.localization?.gmtZoneShort ? visitBuilding.localization.gmtZoneShort
        : GuestPortalConstants.baseTimeZone)],
    isHostDetails: true
  };

  const personalDetails = {
    showAvatar: true,
    icon: 'person',
    avatarName: guest.avatarName,
    details: [`${guest.firstName} ${guest.lastName}`, guest.companyName, guest.phone],
    isHostDetails: false
  };

  const vehicleDetails = {
    showAvatar: false,
    avatarName: '',
    icon: 'car',
    details: activeVisit?.vehicleInfo && (activeVisit.vehicleInfo.make || activeVisit.vehicleInfo.model || activeVisit.vehicleInfo.registrationId)
      ? [`${activeVisit.vehicleInfo.make} ${activeVisit.vehicleInfo.model}`, activeVisit.vehicleInfo.registrationId]
      : [languageContext.dictionary.NoVehicleSaved, ''],
    isHostDetails: false
  };

  let checkinDetails: checkinDetailsType;
  const handleConfirm = (): void => {
    checkinDetails = {
      visitId,
      checkinDetails: {
        guestEmailId,
        buildingId: visitBuilding ? visitBuilding.buildingId : '',
        source: GuestPortalConstants.checkinSource
      }
    };

    dispatch(GuestAction.visitCheckin(checkinDetails));
  };

  const handleBack = (): void => {
    history.push(getRoute('home'));
  };

  const editProfile = (): void => {
    switchView(GuestPortalConstants.EditDetailsView);
  };

  const hideErrorBar = (): void => {
    setShowErrorBar(false);
  };

  return (
    <Stack>
      {showLoader && <Spinner styles={styled.LoaderStyle} size={SpinnerSize.large} />}
      {!showLoader && !isEmailNull && hasActiveVisits && (
        <styled.Container horizontalAlign="center" tokens={styled.ContainerTokens}>
          <styled.Card verticalAlign="space-between">
            {showErrorBar && <ErrorBar onDismiss={hideErrorBar} errorText="UpdateFailureMessage" />}
            <styled.CardTextContainer horizontalAlign="space-between">
              <Stack verticalAlign="space-between" horizontalAlign={isLangRTL ? 'end' : 'start'}>
                <StackItem>
                  <styled.CardTitle
                    role="heading"
                    aria-label={languageContext.dictionary.ValidateYourInfo}
                    aria-level={1}
                  >
                    <Text tid="ValidateYourInfo" />
                  </styled.CardTitle>
                </StackItem>
                <styled.CardSubTitle isRTL={isLangRTL}>
                  <Text tid="ValidateInfoSubText" />
                </styled.CardSubTitle>
              </Stack>
              <styled.SectionTitle horizontal reversed={isLangRTL} role="heading" aria-level={2}>
                <Text tid="meetingDetails" />
              </styled.SectionTitle>
              <ValidateCardSection
                isHostDetails={meetingDetails.isHostDetails}
                showAvatar={meetingDetails.showAvatar}
                avatar={meetingDetails.avatarName}
                data={meetingDetails.details}
                notif={additionalVisits > 0 ? (`+${additionalVisits} 
                ${guestVisitDetail.visitList.length === 1 ? 'meeting' : 'meetings'}
                 in ${visitBuilding?.buildingName}`) : ''}
              />
              <styled.SectionTitle
                horizontal
                reversed={isLangRTL}
                horizontalAlign="space-between"
              >
                <span role="heading" aria-level={2}><Text tid="GuestFormHeader" /></span>
                <styled.EditLink
                  id="edit-profile-button"
                  onClick={editProfile}
                  tabIndex={0}
                  aria-labelledby={languageContext.dictionary.EditPen}
                  aria-describedby="editlink-description"
                >
                  <span id="editlink-description" hidden>
                    {GuestPortalConstants.EditButtonAriaDescription}
                  </span>
                  <Text tid="Edit" />
                  <img src={EditPen} alt="edit" />
                </styled.EditLink>
              </styled.SectionTitle>
              <ValidateCardSection
                isHostDetails={personalDetails.isHostDetails}
                showAvatar={personalDetails.showAvatar}
                avatar={personalDetails.avatarName}
                data={personalDetails.details}
                icon={personalDetails.icon}
              />
              {hasParking === true && (
                <ValidateCardSection
                  isHostDetails={vehicleDetails.isHostDetails}
                  showAvatar={vehicleDetails.showAvatar}
                  avatar={vehicleDetails.avatarName}
                  data={vehicleDetails.details}
                  icon={vehicleDetails.icon}
                />
              )}
              <styled.BadgeSection isRTL={isLangRTL}>
                <styled.GuestBadgeContainer isRTL={isLangRTL}>
                  <styled.GuestBadge>
                    <styled.GuestBadgeHeader
                      horizontal
                      verticalAlign="center"
                      horizontalAlign="space-between"
                    >
                      <styled.GuestBadgeLogo src={MicrosoftLogo} role="presentation" />
                      <styled.GuestBadgeHighlighted>
                        {GuestPortalConstants.Expiry}
                        {moment(activeVisit.scheduledStartTime).tz(timeZone)
                          .add(1, 'd').format('L')}
                      </styled.GuestBadgeHighlighted>
                    </styled.GuestBadgeHeader>
                    <styled.GuestBadgeBody horizontal>
                      <styled.GuestBadgeAvatarContainer
                        verticalAlign="center"
                      >
                        <styled.GuestBadgeAvatar
                          alt={languageContext.dictionary.BadgeImage}
                          src={personalDetails.avatarName && personalDetails.avatarName!==''
                            ? `${Config.CDN_AVATAR_URL}${personalDetails.avatarName}`
                            :  peopleImgPath}
                        />
                      </styled.GuestBadgeAvatarContainer>
                      <styled.GuestBadgeTextContainer
                        tokens={styled.BadgeTextContainerTokens}
                      >
                        <styled.Guestlogo>
                          Guest
                        </styled.Guestlogo>
                        <styled.CustomGuestBadgeName firstLineText={firstLineText}>
                          {firstLineText.length>28 ? (firstLineText.slice(0, 28) + `..`) : firstLineText}
                        </styled.CustomGuestBadgeName>
                        <styled.GuestBadgeName>
                          {lastLineText}
                        </styled.GuestBadgeName>
                        <styled.CompanyName>
                          {(guest.companyName?.length > 12) ? `${guest.companyName.slice(0, 12 - guest.companyName.length)}..` : (guest.companyName)}
                        </styled.CompanyName>
                      </styled.GuestBadgeTextContainer>
                      <styled.ResponsiveQRCode
                        value={qrCode}
                        size={64}
                        includeMargin
                      />

                    </styled.GuestBadgeBody>
                  </styled.GuestBadge>
                </styled.GuestBadgeContainer>
              </styled.BadgeSection>
            </styled.CardTextContainer>
            <styled.CardFooter
              horizontal
              reversed={isLangRTL}
              verticalAlign="center"
              tokens={styled.CardFooterTokens}
            >
              <styled.BlueButton
                id="confirm-checkin-button"
                onClick={handleConfirm}
              >
                <Text tid="Submit" />

              </styled.BlueButton>
              <styled.WhiteButton
                id="cancel-checkin-button"
                onClick={handleBack}
              >
                <Text tid="Cancel" />

              </styled.WhiteButton>
            </styled.CardFooter>
          </styled.Card>
        </styled.Container>
      )}
    </Stack>
  );
};

export default ValidateInfo;
