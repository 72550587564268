/**
 * Config for Non-Prod/PPE environment
 */
export default {
  // Base URL of APIM
  API_BASE_URL: 'https://dwp.azure-api.net/api/v1',
  API_TIMEOUT: 60000,
  CDN_AVATAR_URL: 'https://gmsassets.azureedge.net/avatar/',

  // Region Configuration
  REGION_CONFIG: {
    defaultRegion: 'eu',
    validRegionList: ['us', 'eu']
  },
  
  // Health check
  CovidSurveyLink: 'https://msrtw.powerappsportals.com/',

  // MSAL Adal auth configuration for US and EU region
  AUTH_CONFIG: {
    eu: {
      instance: 'https://lobbyguesteu.b2clogin.com/tfp/',
      tenant: 'lobbyguesteu.onmicrosoft.com',
      signInPolicy: 'b2c_1a_signup_signin',
      resetPolicy: 'b2c_1a_passwordreset',
      applicationId: '3cba6839-d46e-45e8-8686-45d27e07ccc8',
      cacheLocation: 'sessionStorage',
      scopes: ['https://lobbyguesteu.onmicrosoft.com/guest/user_impersonation'],
      redirectUri: 'https://guest.microsoft.com/eu',
      postLogoutRedirectUri: 'https://guest.microsoft.com/eu',
      validateAuthority: false,
      silentLoginOnly: false,
      navigateToLoginRequestUrl: false,
      webApiAcopes: ['https://lobbyguesteu.onmicrosoft.com/guest/user_impersonation']
      // webApiAcopes: ['https://lobbyguesteu.onmicrosoft.com/guestapi/user_impersonation']
    },
    us: {
      instance: 'https://lobbyguestus.b2clogin.com/tfp/',
      tenant: 'lobbyguestus.onmicrosoft.com',
      signInPolicy: 'b2c_1a_signup_signin',
      resetPolicy: 'b2c_1a_passwordreset',
      applicationId: '87fbad1c-b56f-41ef-a2f5-36c8aa25b7ae',
      cacheLocation: 'sessionStorage',
      scopes: ['https://lobbyguestus.onmicrosoft.com/guest/user_impersonation'],
      redirectUri: 'https://guest.microsoft.com/us',
      postLogoutRedirectUri: 'https://guest.microsoft.com/us',
      validateAuthority: false,
      silentLoginOnly: false,
      navigateToLoginRequestUrl: false,
      webApiAcopes: ['https://lobbyguestus.onmicrosoft.com/guest/user_impersonation']
    }
    // webApiAcopes: ['https://lobbyguestus.onmicrosoft.com/guestapi/user_impersonation']
  },

  // Telemetry configuration
  TELEMETRY_CONFIG: {
    environmentName: 'Prod',
    instrumentationKey: '52bac90c-d125-4bb6-88e9-1b95617f2ca6',
    serviceOffering: 'End User Services Engineering',
    serviceLine: ' Digital Workplace',
    service: 'Arrive and Depart',
    componentName: 'Guest Management System',
    componentId: '1263b4bb-89e2-41f4-a076-cda7dbd4658f',
    logLevel: 2, // 0 - off, 1: Critical only 2: Critical,warnings&all
    sessionExpirationTimeInMS: 3600000,
    enableAutoRouteTracking: true,
    enablePropertyObfuscation: false,
    logRequestBody: true
  }

};
