/* eslint-disable import/extensions */
import React from 'react';
import { Provider } from 'react-redux';
import { initializeIcons } from '@uifabric/icons';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router, Switch, Route, Redirect, BrowserRouter
} from 'react-router-dom';
import styled from './App.styled';
import configureStore from './store/configureStore';
import { LanguageProvider } from './providers/LanguageProvider';
import theme from './asset/themes/defaultTheme';
import RouterOutlet from './routes/AppRouterOutlet';
import routesList from './routes/routerConfig';
import config from './env/index';
import RootPageWorldMap from './views/RootPage/RootPage';

// Register icons and pull the fonts from the default SharePoint cdn.
initializeIcons();

// Configure store object
const store = configureStore();


export const Root: React.FC = () => (
  <BrowserRouter>
    <Provider store={store}>
      <LanguageProvider>
        <styled.StyledHeader />
        <RootPageWorldMap />
        <styled.StyledFooter />
      </LanguageProvider>
    </Provider>
  </BrowserRouter>
);


const App: React.FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LanguageProvider>
          <Router>
            <Switch>
              {/* Redirect to default region if not specified */}
              <Redirect
                exact
                from="/"
                to={config.REGION_CONFIG.defaultRegion}
              />
              <Route path={routesList.path}>
                <styled.StyledHeader />
                <main> 
                   <styled.RouterOutletContainer>
                    <RouterOutlet />
                  </styled.RouterOutletContainer>
                    </main>
                <styled.StyledFooter />
              </Route>
            </Switch>
          </Router>
        
      </LanguageProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
