/* eslint-disable import/extensions */
import styled from 'styled-components';
import {
  Stack, Image, FontIcon
} from 'office-ui-fabric-react';
import { size } from '../../config/constants';
import theme from '../../asset/themes/defaultTheme';

const RegionContainer = styled(Stack) <{ isUS: boolean }>` 
  width: ${(props) => (props.isUS ? '44.5%' : '54%')}; 
`;

const ChildContainer = styled(Stack)`
  text-align: center;
  white-space: break-spaces;
  height:100%;
`;

const StackContainer = styled(Stack)`
  width: 100%;
  height: 100%;
  @media(max-width: ${size.laptop}) {
    background: ${theme.brandWhite};
    height: 50.5vh;
  };
  @media(max-width: ${size.mobileXL}) {
    background: ${theme.brandWhite};
    height: 55vh;
  };
  @media(max-width: ${size.mobileS}) {
    background: ${theme.brandWhite};
    height: 57.5vh;
  };
`;


const StyledIcon = styled(FontIcon)`
  font-size: 12px;
  margin-left: 4px;
  font-weight: bold;
`;

const HorizontalContainer = styled(Stack)`
  text-align: center;
  white-space: break-spaces;
  width: 100%;
  padding-top: 3%;
  @media(max-width: ${size.mobileL}) {
    padding-left: 5%;
    padding-right: 5%;
  };
  @media(max-width: ${size.mobileS}) {
    padding-left: 3.5%;
    padding-right: 3.5%;
  };
`;

const Text = styled.span`
  color: ${theme.brandBlack};
  font-size:  ${theme.fontSizeXLSmall};
  @media(max-width: ${size.mobileS}) {
    font-size:  ${theme.fontSizeSmall};
  };
  @media(max-width: ${size.mobileM}) {
    font-size:  ${theme.fontSizeLSmall};
  };
  @media(max-width: ${size.mobileL}) {
    font-size:  ${theme.fontSizeLSmall};
  };
`;

const Link = styled.a`
  color: ${theme.BrandDarkBlue};
  font-weight: bold;
  font-size:  ${theme.fontSizeMedium};
  @media(max-width: ${size.mobileM}) {
    font-size:  ${theme.fontSizeSmall};
  };
  @media(max-width: ${size.mobileL}) {
    font-size:  ${theme.fontSizeLSmall};
  };
  @media(max-width: ${size.mobileXL}) {
    font-size:  ${theme.fontSizeXLSmall};
  };
`;


const Rect = styled.span<{ isMobile: boolean }>` 
  display: inline-block;
  width: 100%;
  height: 25px;
  background: transparent;
  border: dashed;
  border-width: thin;
  border-${(props) => (!props.isMobile ? 'top' : 'bottom')}-left-radius: 6px;
  border-${(props) => (!props.isMobile ? 'top' : 'bottom')}-right-radius: 6px;
  border-${(props) => (props.isMobile ? 'top' : 'bottom')}: transparent;
  border-color: dimgrey;
`;
const StackItemStyle = {
  root:{
    height:'100%'
  }
};

const StackStyle = {
  root:{
    height:'90vh',overflow: 'hidden'
  }
};

export default {
  ChildContainer,
  Text,
  Rect,
  Link,
  HorizontalContainer,
  RegionContainer,
  StackContainer,
  StyledIcon,
  StackItemStyle,
  StackStyle
};
