/* eslint-disable import/extensions */
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IStackTokens, Stack } from 'office-ui-fabric-react';
import styled from './CompleteCheckin.styled';
import { Text, LanguageContext } from '../../../providers/LanguageProvider';
import { GuestPortalConstants } from '../../../config/constants';
import greentick from '../../../asset/images/greentick.svg';
import { getRoute } from '../../../routes/routerConfig';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as GuestAction from '../../../store/guest/guestAction';
import { RootState } from '../../../store/rootReducer';
import * as BuildingSelector from '../../../store/building/buildingSelector';

const ContainerTokens: IStackTokens = {
  childrenGap: 50
};

const CompleteCheckin: React.FC = () => {
  const dispatch = useDispatch();

  const {
    visitBuilding
  } = useSelector((state: RootState) => ({
    visitBuilding: BuildingSelector.visitBuildingSelector(state),
  }), shallowEqual);

  const languageContext = useContext(LanguageContext);

  const history = useHistory();

  const isLangRTL = (languageContext.language.languageType
    === GuestPortalConstants.RtlLanguageType);

  useEffect(() => {
    dispatch(GuestAction.resetCheckinStatus());
  }, []);

  const backToHome = () => {
    history.push(getRoute('home'));
  }

  return (
    <Stack>
      <styled.Container horizontalAlign="center" tokens={ContainerTokens}>
        <styled.Card verticalAlign="space-between">
        <styled.CardContent verticalAlign="center" horizontalAlign={ isLangRTL ? "end" : "start" }>
            <img src={greentick} alt={languageContext.dictionary.CheckInCompleted}></img>
          <styled.TextContainer>
            <styled.CheckInText role="heading" aria-level={1}><Text tid="CheckedIn" /></styled.CheckInText>
            <styled.CheckInSubText><Text tid={ visitBuilding.gmsConfigs.validateID ? "ShowPhotoID" : "ContactLobbyHostToCollectGuestBadge" } /></styled.CheckInSubText>
          </styled.TextContainer>
        </styled.CardContent>
        <styled.CardFooter verticalAlign="center">
            <styled.BlueButton onClick={backToHome}><Text tid="BackToHome" /></styled.BlueButton>
        </styled.CardFooter>
        </styled.Card>
      </styled.Container>
    </Stack>
  )
};

export default CompleteCheckin;
